import { DUST, GENERICS, GREY, GREY_UPDATED, SEA } from '@lib/styles/partials/colors';
import { LAYER } from '@lib/styles/partials/layers';

import { EXPANDABLE_LIST_ARROW_INDICATOR_BOX_SHADOW, EXPANDABLE_LIST_BOX_SHADOW } from './otodompl';
import type { HeaderWidgetVariables } from './type';

// @FIXME - this variables should be deleted and nexus theme should be used instead
export const HEADER_WIDGET_THEME: HeaderWidgetVariables = {
    layer: LAYER,
    finance: {
        header: {
            backgroundColor: DUST.x300,
        },
    },
    menuItem: {
        base: {
            color: GENERICS.white,
        },
        link: {
            default: {
                color: GENERICS.white,
            },
            hover: {
                color: GENERICS.white,
                backgroundColor: '#596064',
            },
            focus: {
                color: GENERICS.white,
                backgroundColor: GREY_UPDATED.x700,
            },
            active: {
                color: GENERICS.white,
                backgroundColor: GREY_UPDATED.x700,
            },
            mobile: {
                active: {
                    color: GREY_UPDATED.x400,
                },
            },
            desktop: {
                arrow: {
                    boxShadow: EXPANDABLE_LIST_ARROW_INDICATOR_BOX_SHADOW,
                },
            },
        },
        subMenu: {
            desktop: {
                boxShadow: EXPANDABLE_LIST_BOX_SHADOW,
            },
            link: {
                default: {
                    color: GENERICS.white,
                },
                desktop: {
                    default: {
                        color: GREY.x500,
                    },
                    hover: {
                        color: SEA.x500,
                    },
                    focus: {
                        color: SEA.x500,
                    },
                },
            },
            highlightedLink: {
                default: { color: SEA.x500 },
                hover: {
                    color: SEA.x400,
                },
            },
        },
        section: {
            color: GREY.x500,
        },
    },
    languageSwitcher: {
        base: {
            color: GENERICS.white,
        },
        selectedLanguage: {
            borderColor: GREY.x500,
            boxShadow: EXPANDABLE_LIST_ARROW_INDICATOR_BOX_SHADOW,
        },
        expanded: {
            default: {
                backgroundColor: GREY_UPDATED.x700,
            },
            hover: {
                backgroundColor: GREY_UPDATED.x700,
            },
            focus: {
                backgroundColor: GREY_UPDATED.x700,
            },
        },
        notExpanded: {
            hover: {
                backgroundColor: '#596064',
            },
            focus: {
                backgroundColor: '#596064',
            },
        },
        list: {
            mobile: {
                color: GENERICS.white,
            },
            desktop: {
                backgroundColor: GREY.x500,
                boxShadow: EXPANDABLE_LIST_BOX_SHADOW,
                color: GENERICS.white,
            },
        },
        link: {
            default: {
                color: GENERICS.white,
            },
            hover: {
                color: SEA.x500,
            },
            focus: {
                color: SEA.x500,
            },
        },
        toggle: {
            borderColor: GENERICS.white,
        },
    },
    menu: {
        base: {
            backgroundColor: '#464b53',
        },
        toggle: {
            color: GENERICS.white,
            borderColor: GENERICS.white,
        },
        separator: {
            backgroundColor: GENERICS.white,
        },
    },
    navUserMenu: {
        static: {
            mobile: {
                color: GENERICS.white,
            },
            default: {
                color: GREY.x500,
            },
            hover: {
                color: SEA.x500,
            },
            focus: {
                color: SEA.x500,
            },
        },
        link: {
            default: {
                color: GENERICS.white,
                backgroundColor: GENERICS.transparent,
            },
            hover: {
                color: GENERICS.white,
                backgroundColor: '#596064',
            },
            focus: {
                color: GENERICS.white,
            },
        },
        list: {
            backgroundColor: GENERICS.white,
            boxShadow: EXPANDABLE_LIST_BOX_SHADOW,
            color: GREY.x500,
        },
        logOut: {
            button: {
                mobile: {
                    color: GENERICS.white,
                },
                default: {
                    color: GREY.x500,
                },
                hover: {
                    color: SEA.x500,
                },
                focus: {
                    color: SEA.x500,
                },
            },
        },
        toggle: {
            borderColor: GENERICS.white,
            boxShadow: EXPANDABLE_LIST_ARROW_INDICATOR_BOX_SHADOW,
        },
        notifications: {
            backgroundColor: SEA.x500,
        },
        separator: {
            backgroundColor: GREY.x200,
        },
    },
    pageHeader: {
        borderColor: GREY.x200,
        backgroundColor: '#464b53',
        color: GENERICS.white,
    },
};
